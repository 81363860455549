@import 'variables';

.Contact {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	.contact-container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.highlight {
			@include highlight;
		}

		h1 {
			@include h1;
		}

		.socials {
			@include socials;
			width: 30%;
		}
	}
}

@media (max-width: 576px) {
	.Contact {
		.contact-container {
			h1 {
				font-size: 25px !important;
			}
		}
	}
}

$secondarycolor: #023cfb;
$primarycolor: #3b04dc;
$complementarycolor: #2be7b3;
$grey: #eee;
$yellow: #ffe75e;

@mixin highlight {
	color: $primarycolor;
	transition: all .1s linear;
	&:hover {
		background: $yellow;
		color: $primarycolor;
	}
}

@mixin h1 {
	font-size: 40px;
	font-family: 'Montserrat';
	font-weight: bold;
	padding: 10px 0px;
}

@mixin socials {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	max-width: 60%;
	align-content: flex-start;
	a {
		color: white;
		box-sizing: border-box;
	}
	i {
		font-size: 30px;
		transition: all .3s;
		padding: 10px;
		color: black;
		&:hover {
			color: $primarycolor;
			cursor: pointer;
		}
	}
}

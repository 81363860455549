@import 'variables';

.App {
	height: 100%;

	.mobile-menu {
		display: none;
	}

	.navbar {
		padding-top: 17px;
		box-sizing: border-box;
		background: white;
		.nav-link {
			color: $primarycolor;
			font-size: 24px;
			font-family: 'PT Sans';
			transition: color .1s linear;

			&::before {
				display: inline-block;
				margin-right: 5px;
				content: '<';
				opacity: 0;
				transform: translateX(20px);
				transition: transform 0.3s, opacity 0.2s;
			}

			&::after {
				content: '/>';
				margin-left: 5px;
				display: inline-block;
				opacity: 0;
				transform: translateX(-20px);
				transition: transform 0.3s, opacity 0.2s;
			}

			&:hover {
				color: black;
				&::after,
				&::before {
					opacity: 1;
					transform: translateX(0px);
				}
			}
		}
		.active-link {
			color: black;
		}
	}
}

@media (max-width: 768px) {
	.App {
		.mobile-menu {
			padding: 10px 0px;
			position: fixed;
			z-index: 99;
			bottom: 0;
			width: 100%;
			background: $primarycolor;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			.mobile-link {
				color: white;
				text-decoration: none;
				font-family: 'Quicksand', sans-serif;
				font-weight: bold;
				font-size: 1.2em;
				display: flex;
				flex-direction: column;
				align-items: center;
				i {
					padding: 5px;
				}
			}
		}

		.navbar {
			display: none;
			.nav-link {
				&::after,
				&::before {
					display: none;
				}
			}
		}
	}
}

@import 'variables';

.Projects {
	height: 100%;
	h1 {
		margin: 0 auto;
		padding: 50px 0px;

		.highlight {
			@include highlight;
		}
	}
	.projects-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 100px;
		justify-content: space-evenly;
		//background: #eee;
		content: '';
	}
}

@media (max-width: 576px) {
	.Projects {
		h1 {
			font-size: 30px !important;
		}
	}
}
